import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _asyncCompose2 from "./utils/asyncCompose";
import _resolveSvg2 from "./steps/resolveSvg";
import _resolveZIndex2 from "./steps/resolveZIndex";
import _resolveAssets2 from "./steps/resolveAssets";
import _resolveStyles2 from "./steps/resolveStyles";
import _resolveOrigins2 from "./steps/resolveOrigins";
import _resolvePageSizes2 from "./steps/resolvePageSizes";
import _resolvePagination2 from "./steps/resolvePagination";
import _resolveDimensions2 from "./steps/resolveDimensions";
import _resolveTextLayout2 from "./steps/resolveTextLayout";
import _resolveInheritance2 from "./steps/resolveInheritance";
import _resolvePagePaddings2 from "./steps/resolvePagePaddings";
import _resolvePercentRadius2 from "./steps/resolvePercentRadius";
import _resolvePercentHeight2 from "./steps/resolvePercentHeight";
import _resolveLinkSubstitution2 from "./steps/resolveLinkSubstitution";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _asyncCompose = _interopRequireDefault(_asyncCompose2);

var _resolveSvg = _interopRequireDefault(_resolveSvg2);

var _resolveZIndex = _interopRequireDefault(_resolveZIndex2);

var _resolveAssets = _interopRequireDefault(_resolveAssets2);

var _resolveStyles = _interopRequireDefault(_resolveStyles2);

var _resolveOrigins = _interopRequireDefault(_resolveOrigins2);

var _resolvePageSizes = _interopRequireDefault(_resolvePageSizes2);

var _resolvePagination = _interopRequireDefault(_resolvePagination2);

var _resolveDimensions = _interopRequireDefault(_resolveDimensions2);

var _resolveTextLayout = _interopRequireDefault(_resolveTextLayout2);

var _resolveInheritance = _interopRequireDefault(_resolveInheritance2);

var _resolvePagePaddings = _interopRequireDefault(_resolvePagePaddings2);

var _resolvePercentRadius = _interopRequireDefault(_resolvePercentRadius2);

var _resolvePercentHeight = _interopRequireDefault(_resolvePercentHeight2);

var _resolveLinkSubstitution = _interopRequireDefault(_resolveLinkSubstitution2); // import * as R from 'ramda';
// const startTimer = name => R.tap(() => console.time(name));
// const endTimer = name => R.tap(() => console.timeEnd(name));


var layout = (0, _asyncCompose.default)(_resolveZIndex.default, _resolveOrigins.default, _resolvePagination.default, _resolveTextLayout.default, _resolvePercentRadius.default, _resolveDimensions.default, _resolveSvg.default, _resolveAssets.default, _resolveInheritance.default, _resolvePercentHeight.default, _resolvePagePaddings.default, _resolveStyles.default, _resolveLinkSubstitution.default, _resolvePageSizes.default);
var _default = layout;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;