import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _getOrientation2 from "./getOrientation";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _getOrientation = _interopRequireDefault(_getOrientation2);
/**
 * Return true if page is landscape
 *
 * @param {Object} page instance
 * @returns {Boolean} is page landscape
 */


var isLandscape = function isLandscape(page) {
  return (0, _getOrientation.default)(page) === "landscape";
};

var _default = isLandscape;
exports.default = _default;
export default exports;