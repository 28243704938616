import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _getPadding3 from "../node/getPadding";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _getPadding2 = _interopRequireDefault(_getPadding3);

var getWrapArea = function getWrapArea(page) {
  var _page$style;

  var _getPadding = (0, _getPadding2.default)(page),
      paddingBottom = _getPadding.paddingBottom;

  var height = (_page$style = page.style) === null || _page$style === void 0 ? void 0 : _page$style.height;
  return height - paddingBottom;
};

var _default = getWrapArea;
exports.default = _default;
export default exports;