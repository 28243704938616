import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import * as _stylesheet3 from "@react-pdf/stylesheet";

var _stylesheet2 = "default" in _stylesheet3 ? _stylesheet3.default : _stylesheet3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var _stylesheet = _interopRequireDefault(_stylesheet2);

var isLink = R.propEq("type", P.Link);
var LINK_STYLES = {
  color: "blue",
  textDecoration: "underline"
};
/**
 * Resolves node styles
 *
 * @param {Object} container
 * @param {Object} document node
 * @returns {Object} node (and subnodes) with resolved styles
 */

var resolveNodeStyles = function resolveNodeStyles(container) {
  return function (node) {
    return R.o(R.when(isLink, R.evolve({
      style: R.merge(LINK_STYLES)
    })), R.evolve({
      style: (0, _stylesheet.default)(container),
      children: R.map(resolveNodeStyles(container))
    }))(node);
  };
};
/**
 * Resolves page styles
 *
 * @param {Object} document page
 * @returns {Object} document page with resolved styles
 */


var resolvePageStyles = function resolvePageStyles(page) {
  var box = R.prop("box", page);
  var style = R.prop("style", page);
  var container = R.isEmpty(box) ? style : box;
  return R.evolve({
    style: (0, _stylesheet.default)(container),
    children: R.map(resolveNodeStyles(container))
  })(page);
};
/**
 * Resolves document styles
 *
 * @param {Object} document root
 * @returns {Object} document root with resolved styles
 */


var _default = R.evolve({
  children: R.map(resolvePageStyles)
});

exports.default = _default;
export default exports;