import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _getMargin2 from "../node/getMargin";
import _getPadding2 from "../node/getPadding";
import _isHeightAuto2 from "../page/isHeightAuto";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _getMargin = _interopRequireDefault(_getMargin2);

var _getPadding = _interopRequireDefault(_getPadding2);

var _isHeightAuto = _interopRequireDefault(_isHeightAuto2);
/* eslint-disable no-param-reassign */


var SAFETY_HEIGHT = 10;
var getMax = R.reduce(R.max, -Infinity);
/**
 * Helper object to predict canvas size
 * TODO: Implement remaining functions (as close as possible);
 */

var measureCtx = function measureCtx() {
  var ctx = {};
  var points = [];

  var nil = function nil() {
    return ctx;
  };

  var addPoint = function addPoint(x, y) {
    return points.push([x, y]);
  };

  var moveTo = R.compose(nil, addPoint);

  var rect = function rect(x, y, w, h) {
    addPoint(x, y);
    addPoint(x + w, y);
    addPoint(x, y + h);
    addPoint(x + w, y + h);
    return ctx;
  };

  var ellipse = function ellipse(x, y, rx, ry) {
    ry = ry || rx;
    addPoint(x - rx, y - ry);
    addPoint(x + rx, y - ry);
    addPoint(x + rx, y + ry);
    addPoint(x - rx, y + ry);
    return ctx;
  };

  var polygon = function polygon() {
    points.push.apply(points, arguments);
    return nil();
  }; // Change dimensions


  ctx.rect = rect;
  ctx.moveTo = moveTo;
  ctx.lineTo = moveTo;
  ctx.circle = ellipse;
  ctx.polygon = polygon;
  ctx.ellipse = ellipse;
  ctx.roundedRect = rect; // To be implemented

  ctx.text = nil;
  ctx.path = nil;
  ctx.lineWidth = nil;
  ctx.bezierCurveTo = nil;
  ctx.quadraticCurveTo = nil;
  ctx.scale = nil;
  ctx.rotate = nil;
  ctx.translate = nil; // These don't change dimensions

  ctx.dash = nil;
  ctx.clip = nil;
  ctx.save = nil;
  ctx.fill = nil;
  ctx.font = nil;
  ctx.stroke = nil;
  ctx.lineCap = nil;
  ctx.opacity = nil;
  ctx.restore = nil;
  ctx.lineJoin = nil;
  ctx.fontSize = nil;
  ctx.fillColor = nil;
  ctx.miterLimit = nil;
  ctx.strokeColor = nil;
  ctx.fillOpacity = nil;
  ctx.strokeOpacity = nil;
  ctx.linearGradient = nil;
  ctx.radialGradient = nil;

  ctx.getWidth = function () {
    return R.compose(getMax, R.pluck(0))(points);
  };

  ctx.getHeight = function () {
    return R.compose(getMax, R.pluck(1))(points);
  };

  return ctx;
};
/**
 * Yoga canvas measure function
 *
 * @param {Object} page
 * @param {Object} node
 * @param {Number} width
 * @param {Number} widthMode
 * @param {Number} height
 * @param {Number} heightMode
 * @returns {Object} canvas width and height
 */


var measureCanvas = function measureCanvas(page, node) {
  var imageMargin = (0, _getMargin.default)(node);
  var pagePadding = (0, _getPadding.default)(page);
  var pageArea = (0, _isHeightAuto.default)(page) ? Infinity : page.box.height - pagePadding.paddingTop - pagePadding.paddingBottom - imageMargin.marginTop - imageMargin.marginBottom - SAFETY_HEIGHT;
  var ctx = measureCtx();
  node.props.paint(ctx);
  var width = ctx.getWidth();
  var height = Math.min(pageArea, ctx.getHeight());
  return {
    height: height,
    width: width
  };
};

var _default = R.curryN(6, measureCanvas);

exports.default = _default;
export default exports;