import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _layoutText2 from "./layoutText";
import _linesWidth2 from "./linesWidth";
import _linesHeight2 from "./linesHeight";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);

var _layoutText = _interopRequireDefault(_layoutText2);

var _linesWidth = _interopRequireDefault(_linesWidth2);

var _linesHeight = _interopRequireDefault(_linesHeight2);
/* eslint-disable no-param-reassign */


var ALIGNMENT_FACTORS = {
  center: 0.5,
  right: 1
};
/**
 * Yoga text measure function
 *
 * @param {Object} page
 * @param {Object} node
 * @param {Number} width
 * @param {Number} widthMode
 * @param {Number} height
 * @param {Number} heightMode
 * @returns {Object} text width and height
 */

var measureText = function measureText(page, node, fontStore, width, widthMode, height) {
  if (widthMode === _yoga.default.MEASURE_MODE_EXACTLY) {
    if (!node.lines) node.lines = (0, _layoutText.default)(node, width, height, fontStore);
    return {
      height: (0, _linesHeight.default)(node)
    };
  }

  if (widthMode === _yoga.default.MEASURE_MODE_AT_MOST) {
    var _node$style;

    var alignFactor = ALIGNMENT_FACTORS[(_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.textAlign] || 0;

    if (!node.lines) {
      node.lines = (0, _layoutText.default)(node, width, height, fontStore);
      node.alignOffset = (width - (0, _linesWidth.default)(node)) * alignFactor; // Compensate align in variable width containers
    }

    return {
      height: (0, _linesHeight.default)(node),
      width: Math.min(width, (0, _linesWidth.default)(node))
    };
  }

  return {};
};

var _default = R.curryN(7, measureText);

exports.default = _default;
export default exports;