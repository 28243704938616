import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);
/**
 * Set justify content attribute to node's Yoga instance
 *
 * @param {String} justify content value
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setJustifyContent = function setJustifyContent(value) {
  return function (node) {
    var yogaNode = node._yogaNode;

    if (!R.isNil(value) && yogaNode) {
      var yogaValue = R.cond([[R.equals("center"), R.always(_yoga.default.JUSTIFY_CENTER)], [R.equals("flex-end"), R.always(_yoga.default.JUSTIFY_FLEX_END)], [R.equals("space-between"), R.always(_yoga.default.JUSTIFY_SPACE_BETWEEN)], [R.equals("space-around"), R.always(_yoga.default.JUSTIFY_SPACE_AROUND)], [R.equals("space-evenly"), R.always(_yoga.default.JUSTIFY_SPACE_EVENLY)], [R.T, R.always(_yoga.default.JUSTIFY_FLEX_START)]])(value);
      yogaNode.setJustifyContent(yogaValue);
    }

    return node;
  };
};

var _default = setJustifyContent;
exports.default = _default;
export default exports;