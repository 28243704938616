import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);
/**
 * Set overflow attribute to node's Yoga instance
 *
 * @param {String} overflow value
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setOverflow = function setOverflow(value) {
  return function (node) {
    var yogaNode = node._yogaNode;

    if (!R.isNil(value) && yogaNode) {
      var yogaValue = R.cond([[R.equals("hidden"), R.always(_yoga.default.OVERFLOW_HIDDEN)], [R.equals("scroll"), R.always(_yoga.default.OVERFLOW_SCROLL)], [R.T, R.always(_yoga.default.OVERFLOW_VISIBLE)]])(value);
      yogaNode.setOverflow(yogaValue);
    }

    return node;
  };
};

var _default = setOverflow;
exports.default = _default;
export default exports;