import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _setYogaValue2 from "./setYogaValue";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.setMaxHeight = exports.setMinHeight = exports.setHeight = exports.setMaxWidth = exports.setMinWidth = exports.setWidth = void 0;

var _setYogaValue = _interopRequireDefault(_setYogaValue2);
/**
 * Set width to node's Yoga instance
 *
 * @param {Number} width
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setWidth = (0, _setYogaValue.default)("width");
/**
 * Set min width to node's Yoga instance
 *
 * @param {Number} min width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setWidth = setWidth;
var setMinWidth = (0, _setYogaValue.default)("minWidth");
/**
 * Set max width to node's Yoga instance
 *
 * @param {Number} max width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMinWidth = setMinWidth;
var setMaxWidth = (0, _setYogaValue.default)("maxWidth");
/**
 * Set height to node's Yoga instance
 *
 * @param {Number} height
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMaxWidth = setMaxWidth;
var setHeight = (0, _setYogaValue.default)("height");
/**
 * Set min height to node's Yoga instance
 *
 * @param {Number} min height
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setHeight = setHeight;
var setMinHeight = (0, _setYogaValue.default)("minHeight");
/**
 * Set max height to node's Yoga instance
 *
 * @param {Number} max height
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMinHeight = setMinHeight;
var setMaxHeight = (0, _setYogaValue.default)("maxHeight");
exports.setMaxHeight = setMaxHeight;
export default exports;