import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _setYogaValue2 from "./setYogaValue";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = exports.setBorder = exports.setBorderLeft = exports.setBorderBottom = exports.setBorderRight = exports.setBorderTop = void 0;

var _yoga = _interopRequireDefault(_yoga2);

var _setYogaValue = _interopRequireDefault(_setYogaValue2);
/**
 * Set border top attribute to node's Yoga instance
 *
 * @param {Number} border top width
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setBorderTop = (0, _setYogaValue.default)("border", _yoga.default.EDGE_TOP);
/**
 * Set border right attribute to node's Yoga instance
 *
 * @param {Number} border right width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setBorderTop = setBorderTop;
var setBorderRight = (0, _setYogaValue.default)("border", _yoga.default.EDGE_RIGHT);
/**
 * Set border bottom attribute to node's Yoga instance
 *
 * @param {Number} border bottom width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setBorderRight = setBorderRight;
var setBorderBottom = (0, _setYogaValue.default)("border", _yoga.default.EDGE_BOTTOM);
/**
 * Set border left attribute to node's Yoga instance
 *
 * @param {Number} border left width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setBorderBottom = setBorderBottom;
var setBorderLeft = (0, _setYogaValue.default)("border", _yoga.default.EDGE_LEFT);
/**
 * Set all border widths at once
 *
 * @param {Number} border width
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setBorderLeft = setBorderLeft;

var setBorder = function setBorder(width) {
  return function (node) {
    setBorderTop(width)(node);
    setBorderRight(width)(node);
    setBorderBottom(width)(node);
    setBorderLeft(width)(node);
    return node;
  };
};

exports.setBorder = setBorder;
var _default = setBorder;
exports.default = _default;
export default exports;