import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _upperFirst2 from "../utils/upperFirst";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);

var _upperFirst = _interopRequireDefault(_upperFirst2);
/**
 * Set generic align attribute to node's Yoga instance
 *
 * @param {String} specific align property
 * @param {String} align value
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setAlign = function setAlign(attr) {
  return function (value) {
    return R.tap(function (node) {
      var yogaNode = node._yogaNode;

      if (yogaNode) {
        var yogaValue = R.cond([[R.equals("flex-start"), R.always(_yoga.default.ALIGN_FLEX_START)], [R.equals("center"), R.always(_yoga.default.ALIGN_CENTER)], [R.equals("flex-end"), R.always(_yoga.default.ALIGN_FLEX_END)], [R.equals("stretch"), R.always(_yoga.default.ALIGN_STRETCH)], [R.equals("baseline"), R.always(_yoga.default.ALIGN_BASELINE)], [R.equals("space-between"), R.always(_yoga.default.ALIGN_SPACE_BETWEEN)], [R.equals("space-around"), R.always(_yoga.default.ALIGN_SPACE_AROUND)], [R.T, R.always(attr === "items" ? _yoga.default.ALIGN_STRETCH : _yoga.default.ALIGN_AUTO)]])(value);
        yogaNode["setAlign" + (0, _upperFirst.default)(attr)](yogaValue);
      }
    });
  };
};

var _default = setAlign;
exports.default = _default;
export default exports;