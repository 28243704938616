import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _setAlign2 from "./setAlign";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _setAlign = _interopRequireDefault(_setAlign2);
/**
 * Set align items attribute to node's Yoga instance
 *
 * @param {String} align value
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setAlignItems = (0, _setAlign.default)("items");
var _default = setAlignItems;
exports.default = _default;
export default exports;