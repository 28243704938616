import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var INHERITED_PROPERTIES = ["color", "fontFamily", "fontSize", "fontStyle", "fontWeight", "letterSpacing", "opacity", "textDecoration", "textTransform", "lineHeight", "textAlign", "visibility", "wordSpacing"];
var isSvg = R.propEq("type", P.Svg);
/**
 * Get styles sub group of inherited properties
 *
 * @param {Object} style object
 * @returns {Object} style object only with inherited properties
 */

var getInheritStyles = R.compose(R.pick(INHERITED_PROPERTIES), R.propOr({}, "style"));
/**
 * Merges styles with node
 *
 * @param {Object} style object
 * @param {Object} node
 * @returns {Object} node with styles merged
 */

var mergeStyles = function mergeStyles(styles) {
  return R.evolve({
    style: R.merge(styles)
  });
};
/**
 * Inherit style values from the root to the leafs
 *
 * @param {Object} document root
 * @returns {Object} document root with inheritance
 *
 */


var resolveInheritance = function resolveInheritance(node) {
  if (isSvg(node)) return node;
  var inheritStyles = getInheritStyles(node);
  var resolveChild = R.compose(resolveInheritance, mergeStyles(inheritStyles));
  return R.evolve({
    children: R.map(resolveChild)
  })(node);
};

var _default = resolveInheritance;
exports.default = _default;
export default exports;