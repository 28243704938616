import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _getOrigin2 from "../node/getOrigin";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _getOrigin = _interopRequireDefault(_getOrigin2);
/**
 * Resolve node origin
 *
 * @param {Object} node
 * @returns {Object} node with origin attribute
 */


var resolveNodeOrigin = function resolveNodeOrigin(node) {
  return R.compose(R.evolve({
    children: R.map(resolveNodeOrigin)
  }), R.converge(R.assoc("origin"), [_getOrigin.default, R.identity]))(node);
};
/**
 * Resolve document origins
 *
 * @param {Object} document root
 * @returns {Object} documrnt root
 */


var resolveOrigin = R.evolve({
  children: R.map(resolveNodeOrigin)
});
var _default = resolveOrigin;
exports.default = _default;
export default exports;