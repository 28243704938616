import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _extends3 from "@babel/runtime/helpers/extends";

var _extends = "default" in _extends3 ? _extends3.default : _extends3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _flatten3 from "@react-pdf/stylesheet/lib/flatten";

var _flatten2 = "default" in _flatten3 ? _flatten3.default : _flatten3;

import _getSize2 from "../page/getSize";
var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = exports.resolvePageSize = void 0;

var _extends2 = _interopRequireDefault(_extends);

var R = _interopRequireWildcard(_ramda);

var _flatten = _interopRequireDefault(_flatten2);

var _getSize = _interopRequireDefault(_getSize2);
/**
 * Resolves page size
 *
 * @param {Object} page
 * @returns {Object} page with resolved size in style attribute
 */


var resolvePageSize = function resolvePageSize(page) {
  var size = (0, _getSize.default)(page);
  var style = (0, _flatten.default)(page.style || {});
  return (0, _extends2.default)({}, page, {
    style: (0, _extends2.default)({}, style, size)
  });
};
/**
 * Resolves page sizes
 *
 * @param {Object} document root
 * @returns {Object} document root with resolved page sizes
 */


exports.resolvePageSize = resolvePageSize;
var resolvePageSizes = R.evolve({
  children: R.map(resolvePageSize)
});
var _default = resolvePageSizes;
exports.default = _default;
export default exports;