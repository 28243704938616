import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _getRatio2 from "./getRatio";
import _getMargin2 from "../node/getMargin";
import _getPadding2 from "../node/getPadding";
import _isHeightAuto2 from "../page/isHeightAuto";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);

var _getRatio = _interopRequireDefault(_getRatio2);

var _getMargin = _interopRequireDefault(_getMargin2);

var _getPadding = _interopRequireDefault(_getPadding2);

var _isHeightAuto = _interopRequireDefault(_isHeightAuto2);

var SAFETY_HEIGHT = 10;
/**
 * Yoga image measure function
 *
 * @param {Object} page
 * @param {Object} node
 * @param {Number} width
 * @param {Number} widthMode
 * @param {Number} height
 * @param {Number} heightMode
 * @returns {Object} image width and height
 */

var measureImage = function measureImage(page, node, width, widthMode, height, heightMode) {
  var imageRatio = (0, _getRatio.default)(node);
  var imageMargin = (0, _getMargin.default)(node);
  var pagePadding = (0, _getPadding.default)(page);
  var pageArea = (0, _isHeightAuto.default)(page) ? Infinity : page.box.height - pagePadding.paddingTop - pagePadding.paddingBottom - imageMargin.marginTop - imageMargin.marginBottom - SAFETY_HEIGHT; // Skip measure if image data not present yet

  if (!node.image) return {
    width: 0,
    height: 0
  };

  if (widthMode === _yoga.default.MEASURE_MODE_EXACTLY && heightMode === _yoga.default.MEASURE_MODE_UNDEFINED) {
    var scaledHeight = width / imageRatio;
    return {
      height: Math.min(pageArea, scaledHeight)
    };
  }

  if (heightMode === _yoga.default.MEASURE_MODE_EXACTLY && (widthMode === _yoga.default.MEASURE_MODE_AT_MOST || widthMode === _yoga.default.MEASURE_MODE_UNDEFINED)) {
    return {
      width: Math.min(height * imageRatio, width)
    };
  }

  if (widthMode === _yoga.default.MEASURE_MODE_EXACTLY && heightMode === _yoga.default.MEASURE_MODE_AT_MOST) {
    var _scaledHeight = width / imageRatio;

    return {
      height: Math.min(height, pageArea, _scaledHeight)
    };
  }

  if (widthMode === _yoga.default.MEASURE_MODE_AT_MOST && heightMode === _yoga.default.MEASURE_MODE_AT_MOST) {
    if (imageRatio > 1) {
      return {
        width: width,
        height: Math.min(width / imageRatio, height)
      };
    }

    return {
      height: height,
      width: Math.min(height * imageRatio, width)
    };
  }

  return {
    height: height,
    width: width
  };
};

var _default = R.curryN(6, measureImage);

exports.default = _default;
export default exports;