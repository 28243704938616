import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _objectWithoutPropertiesLoose3 from "@babel/runtime/helpers/objectWithoutPropertiesLoose";

var _objectWithoutPropertiesLoose = "default" in _objectWithoutPropertiesLoose3 ? _objectWithoutPropertiesLoose3.default : _objectWithoutPropertiesLoose3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives3 from "@react-pdf/primitives";

var _primitives2 = "default" in _primitives3 ? _primitives3.default : _primitives3;

import _castArray2 from "../utils/castArray";
var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _objectWithoutPropertiesLoose2 = _interopRequireDefault(_objectWithoutPropertiesLoose);

var R = _interopRequireWildcard(_ramda);

var _primitives = _primitives2;

var _castArray = _interopRequireDefault(_castArray2);

var isString = R.is(String);
var isNumber = R.is(Number);
var isNotString = R.complement(isString);
/**
 * Transforms a react element instance to internal element format
 *
 * @param {Object} React element
 * @returns {Object} parsed react element
 */

var createInstance = function createInstance(element) {
  if (!element) return null;
  if (isString(element) || isNumber(element)) return {
    type: _primitives.TextInstance,
    value: "" + element
  };
  if (isNotString(element.type)) return createInstance(element.type(element.props));
  var type = element.type,
      _element$props = element.props,
      _element$props$style = _element$props.style,
      style = _element$props$style === void 0 ? {} : _element$props$style,
      _element$props$childr = _element$props.children,
      children = _element$props$childr === void 0 ? [] : _element$props$childr,
      props = (0, _objectWithoutPropertiesLoose2.default)(_element$props, ["style", "children"]);
  var nextChildren = R.compose(R.map(createInstance), _castArray.default)(children);
  return {
    type: type,
    style: style,
    props: props,
    box: {},
    children: nextChildren
  };
};

var _default = createInstance;
exports.default = _default;
export default exports;