import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);
/**
 * Capitalize first letter of string
 *
 * @param {String} string
 * @returns {String} capitalized string
 */


var upperFirst = R.ifElse(R.isNil, R.identity, R.compose(R.join(""), R.juxt([R.compose(R.toUpper, R.head), R.tail])));

var _default = R.memoizeWith(R.identity, upperFirst);

exports.default = _default;
export default exports;