import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import * as _colors3 from "@react-pdf/stylesheet/lib/transform/colors";

var _colors2 = "default" in _colors3 ? _colors3.default : _colors3;

import _layoutText2 from "../svg/layoutText";
import _replaceDefs2 from "../svg/replaceDefs";
import _getContainer2 from "../svg/getContainer";
import _parseViewbox2 from "../svg/parseViewbox";
import _inheritProps2 from "../svg/inheritProps";
import _matchPercent2 from "../utils/matchPercent";
import _parseAspectRatio2 from "../svg/parseAspectRatio";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var _colors = _interopRequireDefault(_colors2);

var _layoutText = _interopRequireDefault(_layoutText2);

var _replaceDefs = _interopRequireDefault(_replaceDefs2);

var _getContainer = _interopRequireDefault(_getContainer2);

var _parseViewbox = _interopRequireDefault(_parseViewbox2);

var _inheritProps = _interopRequireDefault(_inheritProps2);

var _matchPercent = _interopRequireDefault(_matchPercent2);

var _parseAspectRatio = _interopRequireDefault(_parseAspectRatio2);

var STYLE_PROPS = ["width", "height", "color", "stroke", "strokeWidth", "opacity", "fillOpacity", "strokeOpacity", "fill", "fillRule", "clipPath", "offset", "transform", "strokeLinejoin", "strokeLinecap", "strokeDasharray"];
var VERTICAL_PROPS = ["y", "y1", "y2", "height", "cy", "ry"];
var HORIZONTAL_PROPS = ["x", "x1", "x2", "width", "cx", "rx"];
var isType = R.propEq("type");
var isSvg = isType(P.Svg);
var isText = isType(P.Text);
var isTextInstance = isType(P.TextInstance);

var transformPercent = function transformPercent(container) {
  return R.mapObjIndexed(function (value, key) {
    var match = (0, _matchPercent.default)(value);

    if (match && VERTICAL_PROPS.includes(key)) {
      return match.percent * container.height;
    }

    if (match && HORIZONTAL_PROPS.includes(key)) {
      return match.percent * container.width;
    }

    return value;
  });
};

var parsePercent = function parsePercent(value) {
  var match = (0, _matchPercent.default)(value);
  return match ? match.percent : parseFloat(value);
};

var parseProps = function parseProps(container) {
  return R.compose(R.evolve({
    props: R.o(R.evolve({
      x: parseFloat,
      x1: parseFloat,
      x2: parseFloat,
      y: parseFloat,
      y1: parseFloat,
      y2: parseFloat,
      r: parseFloat,
      rx: parseFloat,
      ry: parseFloat,
      cx: parseFloat,
      cy: parseFloat,
      width: parseFloat,
      height: parseFloat,
      offset: parsePercent,
      fill: _colors.default,
      opacity: parsePercent,
      stroke: _colors.default,
      stopOpacity: parsePercent,
      stopColor: _colors.default
    }), transformPercent(container))
  }));
};

var mergeStyles = function mergeStyles(node) {
  var style = R.propOr({}, "style", node);
  return R.evolve({
    props: R.merge(style)
  }, node);
};

var removeNoneValues = R.evolve({
  props: R.map(R.when(R.equals("none"), R.always(null)))
});

var pickStyleProps = function pickStyleProps(node) {
  var styleProps = R.o(R.pick(STYLE_PROPS), R.propOr({}, "props"))(node);
  return R.evolve({
    style: R.merge(styleProps)
  }, node);
};

var parseSvgProps = R.evolve({
  props: R.evolve({
    width: parseFloat,
    height: parseFloat,
    viewBox: _parseViewbox.default,
    preserveAspectRatio: _parseAspectRatio.default
  })
});

var wrapBetweenTspan = function wrapBetweenTspan(node) {
  return {
    type: "TSPAN",
    props: {},
    children: [node]
  };
};

var addMissingTspan = R.when(isText, R.evolve({
  children: R.map(R.when(isTextInstance, wrapBetweenTspan))
}));

var resolveSvgNode = function resolveSvgNode(container) {
  return R.compose(parseProps(container), addMissingTspan, removeNoneValues, mergeStyles);
};

var resolveChildren = function resolveChildren(container) {
  return function (node) {
    return R.evolve({
      children: R.map(R.compose(resolveChildren(container), resolveSvgNode(container)))
    })(node);
  };
};

var parseText = function parseText(fontStore) {
  return function (node) {
    return R.ifElse(isText, (0, _layoutText.default)(fontStore), R.evolve({
      children: R.map(parseText(fontStore))
    }))(node);
  };
};

var resolveSvgRoot = function resolveSvgRoot(fontStore) {
  return function (node) {
    var container = (0, _getContainer.default)(node);
    return R.compose(_replaceDefs.default, parseText(fontStore), parseSvgProps, pickStyleProps, _inheritProps.default, resolveChildren(container))(node);
  };
};
/**
 * Pre-process SVG nodes so they can be rendered in the next steps
 *
 * @param {Object} root node
 * @param {Object} fontStore font store
 * @returns {Object} root node
 */


var resolveSvg = function resolveSvg(node, fontStore) {
  var mapChild = function mapChild(child) {
    return resolveSvg(child, fontStore);
  };

  return R.compose(R.evolve({
    children: R.map(mapChild)
  }), R.when(isSvg, resolveSvgRoot(fontStore)))(node);
};

var _default = resolveSvg;
exports.default = _default;
export default exports;