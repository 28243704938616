import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _layout3 from "@react-pdf/textkit/lib/layout";

var _layout2 = "default" in _layout3 ? _layout3.default : _layout3;

import * as _linebreaker3 from "@react-pdf/textkit/lib/engines/linebreaker";

var _linebreaker2 = "default" in _linebreaker3 ? _linebreaker3.default : _linebreaker3;

import * as _justification3 from "@react-pdf/textkit/lib/engines/justification";

var _justification2 = "default" in _justification3 ? _justification3.default : _justification3;

import * as _textDecoration3 from "@react-pdf/textkit/lib/engines/textDecoration";

var _textDecoration2 = "default" in _textDecoration3 ? _textDecoration3.default : _textDecoration3;

import * as _scriptItemizer3 from "@react-pdf/textkit/lib/engines/scriptItemizer";

var _scriptItemizer2 = "default" in _scriptItemizer3 ? _scriptItemizer3.default : _scriptItemizer3;

import * as _wordHyphenation3 from "@react-pdf/textkit/lib/engines/wordHyphenation";

var _wordHyphenation2 = "default" in _wordHyphenation3 ? _wordHyphenation3.default : _wordHyphenation3;

import _fontSubstitution2 from "./fontSubstitution";
import _getAttributedString2 from "./getAttributedString";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _layout = _interopRequireDefault(_layout2);

var _linebreaker = _interopRequireDefault(_linebreaker2);

var _justification = _interopRequireDefault(_justification2);

var _textDecoration = _interopRequireDefault(_textDecoration2);

var _scriptItemizer = _interopRequireDefault(_scriptItemizer2);

var _wordHyphenation = _interopRequireDefault(_wordHyphenation2);

var _fontSubstitution = _interopRequireDefault(_fontSubstitution2);

var _getAttributedString = _interopRequireDefault(_getAttributedString2);

var engines = {
  linebreaker: _linebreaker.default,
  justification: _justification.default,
  textDecoration: _textDecoration.default,
  scriptItemizer: _scriptItemizer.default,
  wordHyphenation: _wordHyphenation.default,
  fontSubstitution: _fontSubstitution.default
};
var engine = (0, _layout.default)(engines);
var getMaxLines = R.path(["style", "maxLines"]);
var getTextOverflow = R.path(["style", "textOverflow"]);
/**
 * Get layout container for specific text node
 *
 * @param {Number} width
 * @param {Number} height
 * @param {Object} node
 * @returns {Object} layout container
 */

var getContainer = function getContainer(width, height, node) {
  var maxLines = getMaxLines(node);
  var textOverflow = getTextOverflow(node);
  return {
    x: 0,
    y: 0,
    width: width,
    maxLines: maxLines,
    height: height || Infinity,
    truncateMode: textOverflow
  };
};
/**
 * Get text layout options for specific text node
 *
 * @param {Object} node instance
 * @returns {Object} layout options
 */


var getLayoutOptions = function getLayoutOptions(fontStore, node) {
  return {
    hyphenationPenalty: node.props.hyphenationPenalty,
    shrinkWhitespaceFactor: {
      before: -0.5,
      after: -0.5
    },
    hyphenationCallback: node.props.hyphenationCallback || (fontStore === null || fontStore === void 0 ? void 0 : fontStore.getHyphenationCallback()) || null
  };
};
/**
 * Get text lines for given node
 *
 * @param {Object} node
 * @param {Number} container width
 * @param {Number} container height
 * @param {Number} fontStore font store
 * @returns {Array} layout lines
 */


var layoutText = function layoutText(node, width, height, fontStore) {
  var attributedString = (0, _getAttributedString.default)(fontStore, node);
  var container = getContainer(width, height, node);
  var options = getLayoutOptions(fontStore, node);
  var lines = engine(attributedString, container, options);
  return R.reduce(R.concat, [], lines);
};

var _default = R.curryN(4, layoutText);

exports.default = _default;
export default exports;