import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives3 from "@react-pdf/primitives";

var _primitives2 = "default" in _primitives3 ? _primitives3.default : _primitives3;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _primitives = _primitives2;
var getZIndex = R.path(["style", "zIndex"]);
var isType = R.propEq("type");
var shouldNotSort = R.anyPass([isType(_primitives.Document), isType(_primitives.Svg)]);

var sortZIndex = function sortZIndex(a, b) {
  var za = getZIndex(a);
  var zb = getZIndex(b);
  if (!za && !zb) return 0;
  if (!za) return 1;
  if (!zb) return -1;
  return zb - za;
};
/**
 * Sort children by zIndex value
 *
 * @param {Object} node
 * @returns {Object} node
 */


var resolveZIndex = function resolveZIndex(node) {
  return R.compose(R.evolve({
    children: R.map(resolveZIndex)
  }), R.unless(shouldNotSort, R.evolve({
    children: R.sort(sortZIndex)
  })))(node);
};

var _default = resolveZIndex;
exports.default = _default;
export default exports;