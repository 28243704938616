import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var isType = R.propEq("type");
var isSvg = isType(P.Svg);
var isNote = isType(P.Note);
var isImage = isType(P.Image);
var isCanvas = isType(P.Canvas);
var getWrap = R.ifElse(R.anyPass([isSvg, isNote, isImage, isCanvas]), R.always(false), R.pathOr(true, ["props", "wrap"]));
var _default = getWrap;
exports.default = _default;
export default exports;