import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _setYogaValue2 from "./setYogaValue";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = exports.setPosition = exports.setPositionLeft = exports.setPositionBottom = exports.setPositionRight = exports.setPositionTop = void 0;

var _yoga = _interopRequireDefault(_yoga2);

var _setYogaValue = _interopRequireDefault(_setYogaValue2);
/**
 * Set position top attribute to node's Yoga instance
 *
 * @param {Number} position top
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setPositionTop = (0, _setYogaValue.default)("position", _yoga.default.EDGE_TOP);
/**
 * Set position right attribute to node's Yoga instance
 *
 * @param {Number} position right
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setPositionTop = setPositionTop;
var setPositionRight = (0, _setYogaValue.default)("position", _yoga.default.EDGE_RIGHT);
/**
 * Set position bottom attribute to node's Yoga instance
 *
 * @param {Number} position bottom
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setPositionRight = setPositionRight;
var setPositionBottom = (0, _setYogaValue.default)("position", _yoga.default.EDGE_BOTTOM);
/**
 * Set position left attribute to node's Yoga instance
 *
 * @param {Number} position left
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setPositionBottom = setPositionBottom;
var setPositionLeft = (0, _setYogaValue.default)("position", _yoga.default.EDGE_LEFT);
/**
 * Set all positions at once
 *
 * @param {Number} position
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setPositionLeft = setPositionLeft;

var setPosition = function setPosition(position) {
  return function (node) {
    setPositionTop(position)(node);
    setPositionRight(position)(node);
    setPositionBottom(position)(node);
    setPositionLeft(position)(node);
    return node;
  };
};

exports.setPosition = setPosition;
var _default = setPosition;
exports.default = _default;
export default exports;