import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _yoga = _interopRequireDefault(_yoga2);

var getComputedBorder = function getComputedBorder(yogaNode, edge) {
  return yogaNode ? yogaNode.getComputedBorder(edge) : 0;
};
/**
 * Get Yoga computed border width. Zero otherwise
 *
 * @param {Object} node
 * @return {Object} border widths
 */


var getBorderWidth = function getBorderWidth(node) {
  var yogaNode = node._yogaNode;
  return {
    borderTopWidth: getComputedBorder(yogaNode, _yoga.default.EDGE_TOP),
    borderRightWidth: getComputedBorder(yogaNode, _yoga.default.EDGE_RIGHT),
    borderBottomWidth: getComputedBorder(yogaNode, _yoga.default.EDGE_BOTTOM),
    borderLeftWidth: getComputedBorder(yogaNode, _yoga.default.EDGE_LEFT)
  };
};

var _default = getBorderWidth;
exports.default = _default;
export default exports;