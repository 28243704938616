import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _getWrap2 from "./getWrap";
import _getNodesHeight2 from "./getNodesHeight";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _getWrap = _interopRequireDefault(_getWrap2);

var _getNodesHeight = _interopRequireDefault(_getNodesHeight2);
/* eslint-disable no-continue */


var getBreak = R.pathOr(false, ["props", "break"]);
var getMinPresenceAhead = R.path(["props", "minPresenceAhead"]);

var defaultPresenceAhead = function defaultPresenceAhead(element) {
  return function (height) {
    return Math.min(element.box.height, height);
  };
};

var getPresenceAhead = function getPresenceAhead(elements, height) {
  var result = 0;

  for (var i = 0; i < elements.length; i += 1) {
    var element = elements[i];
    if (!element.box) continue;
    var isElementInside = height > element.box.top;
    var presenceAhead = element.props.presenceAhead || defaultPresenceAhead(element);

    if (element && isElementInside) {
      result += presenceAhead(height - element.box.top);
    }
  }

  return result;
};

var shouldBreak = function shouldBreak(child, futureElements, height) {
  var minPresenceAhead = getMinPresenceAhead(child);
  var presenceAhead = getPresenceAhead(futureElements, height);
  var futureHeight = (0, _getNodesHeight.default)(futureElements);
  var shouldSplit = height < child.box.top + child.box.height;
  var shouldWrap = (0, _getWrap.default)(child);
  return getBreak(child) || !shouldWrap && shouldSplit || minPresenceAhead < futureHeight && presenceAhead < minPresenceAhead;
};

var _default = shouldBreak;
exports.default = _default;
export default exports;