import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var zero = R.always(0);
var getTop = R.pathOr(0, ["box", "top"]);
var hasFixedHeight = R.hasPath(["style", "height"]);

var subtractHeight = function subtractHeight(value) {
  return R.o(R.subtract(R.__, value), R.path(["box", "height"]));
};

var splitNode = function splitNode(node, height) {
  if (!node) return [null, null];
  var nodeTop = getTop(node); // TODO: We should keep style untouched

  var current = R.evolve({
    style: R.evolve({
      marginBottom: zero,
      paddingBottom: zero,
      borderBottomWidth: zero,
      borderBottomLeftRadius: zero,
      borderBottomRightRadius: zero
    }),
    box: {
      height: R.always(height - nodeTop),
      borderBottomWidth: zero
    }
  })(node);
  var nextHeight = R.ifElse(hasFixedHeight, subtractHeight(height - nodeTop), R.always(null))(node); // TODO: We should keep style untouched

  var next = R.evolve({
    style: R.evolve({
      marginTop: zero,
      paddingTop: zero,
      borderTopWidth: zero,
      borderTopLeftRadius: zero,
      borderTopRightRadius: zero
    }),
    box: {
      top: zero,
      height: R.always(nextHeight),
      borderTopWidth: zero
    }
  })(node);
  return [current, next];
};

var _default = splitNode;
exports.default = _default;
export default exports;