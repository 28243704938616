import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _capitalize2 from "../utils/capitalize";
import _upperFirst2 from "../utils/upperFirst";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _capitalize = _interopRequireDefault(_capitalize2);

var _upperFirst = _interopRequireDefault(_upperFirst2);
/**
 * Apply transformation to text string
 *
 * @param {String} text
 * @param {String} transformation type
 * @returns {String} transformed text
 */


var transformText = function transformText(text, transformation) {
  switch (transformation) {
    case "uppercase":
      return text.toUpperCase();

    case "lowercase":
      return text.toLowerCase();

    case "capitalize":
      return (0, _capitalize.default)(text);

    case "upperfirst":
      return (0, _upperFirst.default)(text);

    default:
      return text;
  }
};

var _default = transformText;
exports.default = _default;
export default exports;