import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _yoga = _interopRequireDefault(_yoga2);
/**
 * Set display attribute to node's Yoga instance
 *
 * @param {String} display
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setDisplay = function setDisplay(value) {
  return function (node) {
    var yogaNode = node._yogaNode;

    if (yogaNode) {
      yogaNode.setDisplay(value === "none" ? _yoga.default.DISPLAY_NONE : _yoga.default.DISPLAY_FLEX);
    }

    return node;
  };
};

var _default = setDisplay;
exports.default = _default;
export default exports;