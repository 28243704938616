import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var isType = R.propEq("type");
var isLink = isType(P.Link);
var isTextInstance = isType(P.TextInstance);
/**
 * Checks if node has render prop
 *
 * @param {Object} node
 * @returns {Boolean} has render prop?
 */

var hasRenderProp = R.hasPath(["props", "render"]);
/**
 * Checks if all children of node are text instances
 *
 * @param {Object} node
 * @returns {Boolean} are all children text instances?
 */

var hasTextInstanceChilds = R.compose(R.all(isTextInstance), R.propOr([], "children"));
/**
 * If the Link has a string child or render prop, substitute the instance by a Text,
 * that will ultimately render the inline Link via the textkit PDF renderer.
 *
 * @param {Object} node
 * @returns {Object} node with link substitution
 */

var resolveLinkSubstitution = function resolveLinkSubstitution(node) {
  return R.evolve({
    children: R.map(R.ifElse(R.both(isLink, R.either(hasRenderProp, hasTextInstanceChilds)), R.assoc("type", P.Text), resolveLinkSubstitution))
  })(node);
};

var _default = resolveLinkSubstitution;
exports.default = _default;
export default exports;