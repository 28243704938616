import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import _getMargin2 from "../node/getMargin";
import _getPadding2 from "../node/getPadding";
import _getPosition2 from "../node/getPosition";
import _getDimension2 from "../node/getDimension";
import _getBorderWidth2 from "../node/getBorderWidth";
import _setDisplay2 from "../node/setDisplay";
import _setOverflow2 from "../node/setOverflow";
import _setFlexWrap2 from "../node/setFlexWrap";
import _setFlexGrow2 from "../node/setFlexGrow";
import _setFlexBasis2 from "../node/setFlexBasis";
import _setAlignSelf2 from "../node/setAlignSelf";
import _setAlignItems2 from "../node/setAlignItems";
import _setFlexShrink2 from "../node/setFlexShrink";
import _setAspectRatio2 from "../node/setAspectRatio";
import _setAlignContent2 from "../node/setAlignContent";
import _setPositionType2 from "../node/setPositionType";
import _setFlexDirection2 from "../node/setFlexDirection";
import _setJustifyContent2 from "../node/setJustifyContent";
import _setMargin2 from "../node/setMargin";
import _setPadding2 from "../node/setPadding";
import _setBorderWidth2 from "../node/setBorderWidth";
import _setPosition2 from "../node/setPosition";
import _setDimension2 from "../node/setDimension";
import _measureSvg2 from "../svg/measureSvg";
import _measureText2 from "../text/measureText";
import _measureImage2 from "../image/measureImage";
import _measureCanvas2 from "../canvas/measureCanvas";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = exports.resolvePageDimensions = void 0;

var R = _interopRequireWildcard(_ramda);

var _yoga = _interopRequireDefault(_yoga2);

var P = _interopRequireWildcard(_primitives);

var _getMargin = _interopRequireDefault(_getMargin2);

var _getPadding = _interopRequireDefault(_getPadding2);

var _getPosition = _interopRequireDefault(_getPosition2);

var _getDimension = _interopRequireDefault(_getDimension2);

var _getBorderWidth = _interopRequireDefault(_getBorderWidth2);

var _setDisplay = _interopRequireDefault(_setDisplay2);

var _setOverflow = _interopRequireDefault(_setOverflow2);

var _setFlexWrap = _interopRequireDefault(_setFlexWrap2);

var _setFlexGrow = _interopRequireDefault(_setFlexGrow2);

var _setFlexBasis = _interopRequireDefault(_setFlexBasis2);

var _setAlignSelf = _interopRequireDefault(_setAlignSelf2);

var _setAlignItems = _interopRequireDefault(_setAlignItems2);

var _setFlexShrink = _interopRequireDefault(_setFlexShrink2);

var _setAspectRatio = _interopRequireDefault(_setAspectRatio2);

var _setAlignContent = _interopRequireDefault(_setAlignContent2);

var _setPositionType = _interopRequireDefault(_setPositionType2);

var _setFlexDirection = _interopRequireDefault(_setFlexDirection2);

var _setJustifyContent = _interopRequireDefault(_setJustifyContent2);

var _setMargin = _setMargin2;
var _setPadding = _setPadding2;
var _setBorderWidth = _setBorderWidth2;
var _setPosition = _setPosition2;
var _setDimension = _setDimension2;

var _measureSvg = _interopRequireDefault(_measureSvg2);

var _measureText = _interopRequireDefault(_measureText2);

var _measureImage = _interopRequireDefault(_measureImage2);

var _measureCanvas = _interopRequireDefault(_measureCanvas2);

var YOGA_NODE = "_yogaNode";

var YOGA_CONFIG = _yoga.default.Config.create();

YOGA_CONFIG.setPointScaleFactor(0);
var isType = R.propEq("type");
var isSvg = isType(P.Svg);
var isText = isType(P.Text);
var isNote = isType(P.Note);
var isPage = isType(P.Page);
var isImage = isType(P.Image);
var isCanvas = isType(P.Canvas);
var isTextInstance = isType(P.TextInstance);

var setNodeHeight = function setNodeHeight(node) {
  return R.ifElse(isPage, (0, _setDimension.setHeight)(node.box.height), (0, _setDimension.setHeight)(node.box.height || node.style.height));
};
/**
 * Set styles valeus into yoga node before layout calculation
 *
 * @param {Object} node
 * @returns {Object} node
 */


var setYogaValues = R.tap(function (node) {
  R.compose(setNodeHeight(node), (0, _setDimension.setWidth)(node.style.width), (0, _setDimension.setMinWidth)(node.style.minWidth), (0, _setDimension.setMaxWidth)(node.style.maxWidth), (0, _setDimension.setMinHeight)(node.style.minHeight), (0, _setDimension.setMaxHeight)(node.style.maxHeight), (0, _setMargin.setMarginTop)(node.style.marginTop), (0, _setMargin.setMarginRight)(node.style.marginRight), (0, _setMargin.setMarginBottom)(node.style.marginBottom), (0, _setMargin.setMarginLeft)(node.style.marginLeft), (0, _setPadding.setPaddingTop)(node.style.paddingTop), (0, _setPadding.setPaddingRight)(node.style.paddingRight), (0, _setPadding.setPaddingBottom)(node.style.paddingBottom), (0, _setPadding.setPaddingLeft)(node.style.paddingLeft), (0, _setPositionType.default)(node.style.position), (0, _setPosition.setPositionTop)(node.style.top), (0, _setPosition.setPositionRight)(node.style.right), (0, _setPosition.setPositionBottom)(node.style.bottom), (0, _setPosition.setPositionLeft)(node.style.left), (0, _setBorderWidth.setBorderTop)(node.style.borderTopWidth), (0, _setBorderWidth.setBorderRight)(node.style.borderRightWidth), (0, _setBorderWidth.setBorderBottom)(node.style.borderBottomWidth), (0, _setBorderWidth.setBorderLeft)(node.style.borderLeftWidth), (0, _setDisplay.default)(node.style.display), (0, _setFlexDirection.default)(node.style.flexDirection), (0, _setAlignSelf.default)(node.style.alignSelf), (0, _setAlignContent.default)(node.style.alignContent), (0, _setAlignItems.default)(node.style.alignItems), (0, _setJustifyContent.default)(node.style.justifyContent), (0, _setFlexWrap.default)(node.style.flexWrap), (0, _setOverflow.default)(node.style.overflow), (0, _setAspectRatio.default)(node.style.aspectRatio), (0, _setFlexBasis.default)(node.style.flexBasis), (0, _setFlexGrow.default)(node.style.flexGrow), (0, _setFlexShrink.default)(node.style.flexShrink))(node);
});
/**
 * Inserts child into parent' yoga node
 *
 * @param {Object} parent
 * @param {Object} node
 * @param {Object} node
 */

var insertYogaNodes = function insertYogaNodes(parent) {
  return R.tap(function (child) {
    return parent.insertChild(child[YOGA_NODE], parent.getChildCount());
  });
};

var setMeasureFunc = function setMeasureFunc(page, fontStore) {
  return function (node) {
    var yogaNode = node[YOGA_NODE];

    if (isText(node)) {
      yogaNode.setMeasureFunc((0, _measureText.default)(page, node, fontStore));
    }

    if (isImage(node)) {
      yogaNode.setMeasureFunc((0, _measureImage.default)(page, node));
    }

    if (isCanvas(node)) {
      yogaNode.setMeasureFunc((0, _measureCanvas.default)(page, node));
    }

    if (isSvg(node)) {
      yogaNode.setMeasureFunc((0, _measureSvg.default)(page, node));
    }

    return node;
  };
};

var isNotText = R.complement(isText);
var isNotNote = R.complement(isNote);
var isNotSvg = R.complement(isSvg);
var isNotTextInstance = R.complement(isTextInstance);
var isLayoutElement = R.allPass([isNotText, isNotNote, isNotSvg]);
/**
 * Creates and add yoga node to document tree
 * Handles measure function for text and image nodes
 *
 * @param {Object} node
 * @returns {Object} node with appended yoga node
 */

var createYogaNodes = function createYogaNodes(page, fontStore) {
  return function (node) {
    var yogaNode = _yoga.default.Node.createWithConfig(YOGA_CONFIG);

    return R.compose(setMeasureFunc(page, fontStore), R.when(isLayoutElement, R.evolve({
      children: R.map(R.compose(insertYogaNodes(yogaNode), createYogaNodes(page, fontStore)))
    })), setYogaValues, R.assoc(YOGA_NODE, yogaNode))(node);
  };
};
/**
 * Performs yoga calculation
 *
 * @param {Object} node
 * @returns {Object} node
 */


var calculateLayout = function calculateLayout(page) {
  page[YOGA_NODE].calculateLayout();
  return page;
};
/**
 * Saves Yoga layout result into 'box' attribute of node
 *
 * @param {Object} node
 * @returns {Object} node with box data
 */


var persistDimensions = function persistDimensions(node) {
  return R.evolve({
    children: R.map(R.when(isNotTextInstance, persistDimensions)),
    box: R.always(R.mergeAll([(0, _getPadding.default)(node), (0, _getMargin.default)(node), (0, _getBorderWidth.default)(node), (0, _getPosition.default)(node), (0, _getDimension.default)(node)]))
  })(node);
};
/**
 * Removes yoga node from document tree
 *
 * @param {Object} node
 * @returns {Object} node without yoga node
 */


var destroyYogaNodes = function destroyYogaNodes(node) {
  return R.compose(R.dissoc(YOGA_NODE), R.evolve({
    children: R.map(destroyYogaNodes)
  }))(node);
};
/**
 * Free yoga node from document tree
 *
 * @param {Object} node
 * @returns {Object} node without yoga node
 */


var freeYogaNodes = function freeYogaNodes(node) {
  if (node[YOGA_NODE]) node[YOGA_NODE].freeRecursive();
  return node;
};
/**
 * Calculates page object layout using Yoga.
 * Takes node values from 'box' and 'style' attributes, and persist them back into 'box'
 * Destroy yoga values at the end.
 *
 * @param {Object} page object
 * @returns {Object} page object with correct 'box' layout attributes
 */


var resolvePageDimensions = function resolvePageDimensions(page, fontStore) {
  return R.ifElse(R.isNil, R.always(null), R.compose(destroyYogaNodes, freeYogaNodes, persistDimensions, calculateLayout, createYogaNodes(page, fontStore)))(page);
};
/**
 * Calculates root object layout using Yoga.
 *
 * @param {Object} root object
 * @returns {Object} root object with correct 'box' layout attributes
 */


exports.resolvePageDimensions = resolvePageDimensions;

var resolveDimensions = function resolveDimensions(node, fontStore) {
  var mapChild = function mapChild(child) {
    return resolvePageDimensions(child, fontStore);
  };

  return R.evolve({
    children: R.map(mapChild)
  })(node);
};

var _default = resolveDimensions;
exports.default = _default;
export default exports;