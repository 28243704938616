import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import _layoutText2 from "../text/layoutText";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var _layoutText = _interopRequireDefault(_layoutText2);

var isType = R.propEq("type");
var isSvg = isType(P.Svg);
var isText = isType(P.Text);
var isNotSvg = R.complement(isSvg);

var shouldLayoutText = function shouldLayoutText(node) {
  return isText(node) && !node.lines;
};
/**
 * Performs text layout on text node if wasn't calculated before.
 * Text layout is usually performed on Yoga's layout process (via setMeasureFunc),
 * but we need to layout those nodes with fixed width and height.
 *
 * @param {Object} node
 * @returns {Object} layout node
 */


var resolveTextLayout = function resolveTextLayout(node, fontStore) {
  var mapChild = function mapChild(child) {
    return resolveTextLayout(child, fontStore);
  };

  return R.compose(R.evolve({
    children: R.map(R.when(isNotSvg, mapChild))
  }), R.when(shouldLayoutText, R.compose(R.converge(R.assoc("lines"), [R.converge(_layoutText.default, [R.identity, R.path(["box", "width"]), R.path(["box", "height"]), R.always(fontStore)]), R.identity]))))(node);
};

var _default = resolveTextLayout;
exports.default = _default;
export default exports;