import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _attributedString3 from "@react-pdf/textkit/lib/attributedString";

var _attributedString2 = "default" in _attributedString3 ? _attributedString3.default : _attributedString3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _attributedString = _interopRequireDefault(_attributedString2);
/**
 * Get lines width (if any)
 *
 * @param {Object} node
 * @returns {Number} lines width
 */


var linesWidth = function linesWidth(node) {
  if (!node.lines) return -1;
  return Math.max.apply(Math, node.lines.map(function (line) {
    return _attributedString.default.advanceWidth(line);
  }));
};

var _default = linesWidth;
exports.default = _default;
export default exports;