import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _yoga3 from "@react-pdf/yoga";

var _yoga2 = "default" in _yoga3 ? _yoga3.default : _yoga3;

import _setYogaValue2 from "./setYogaValue";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = exports.setMargin = exports.setMarginLeft = exports.setMarginBottom = exports.setMarginRight = exports.setMarginTop = void 0;

var _yoga = _interopRequireDefault(_yoga2);

var _setYogaValue = _interopRequireDefault(_setYogaValue2);
/**
 * Set margin top attribute to node's Yoga instance
 *
 * @param {Number} margin top
 * @param {Object} node instance
 * @return {Object} node instance
 */


var setMarginTop = (0, _setYogaValue.default)("margin", _yoga.default.EDGE_TOP);
/**
 * Set margin right attribute to node's Yoga instance
 *
 * @param {Number} margin right
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMarginTop = setMarginTop;
var setMarginRight = (0, _setYogaValue.default)("margin", _yoga.default.EDGE_RIGHT);
/**
 * Set margin bottom attribute to node's Yoga instance
 *
 * @param {Number} margin bottom
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMarginRight = setMarginRight;
var setMarginBottom = (0, _setYogaValue.default)("margin", _yoga.default.EDGE_BOTTOM);
/**
 * Set margin left attribute to node's Yoga instance
 *
 * @param {Number} margin left
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMarginBottom = setMarginBottom;
var setMarginLeft = (0, _setYogaValue.default)("margin", _yoga.default.EDGE_LEFT);
/**
 * Set all margins at once
 *
 * @param {Number} margin
 * @param {Object} node instance
 * @return {Object} node instance
 */

exports.setMarginLeft = setMarginLeft;

var setMargin = function setMargin(margin) {
  return function (node) {
    setMarginTop(margin)(node);
    setMarginRight(margin)(node);
    setMarginBottom(margin)(node);
    setMarginLeft(margin)(node);
    return node;
  };
};

exports.setMargin = setMargin;
var _default = setMargin;
exports.default = _default;
export default exports;