import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import _getDefs2 from "./getDefs";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var _getDefs = _interopRequireDefault(_getDefs2);

var isDefs = R.propEq("type", P.Defs);
var isNotDefs = R.complement(isDefs);
var detachDefs = R.evolve({
  children: R.filter(isNotDefs)
});
var URL_REGEX = /url\(['"]?#([^'"]+)['"]?\)/;

var replaceDef = function replaceDef(defs) {
  return R.compose(R.when(R.test(URL_REGEX), R.compose(R.prop(R.__, defs), R.prop(1), R.match(URL_REGEX))), R.defaultTo(""));
};

var parseNodeDefs = function parseNodeDefs(defs) {
  return function (node) {
    return R.compose(R.evolve({
      props: R.evolve({
        fill: replaceDef(defs),
        clipPath: replaceDef(defs)
      })
    }), R.evolve({
      children: R.map(parseNodeDefs(defs))
    }))(node);
  };
};

var parseDefs = function parseDefs(root) {
  var defs = (0, _getDefs.default)(root);
  return R.evolve({
    children: R.map(parseNodeDefs(defs))
  }, root);
};

var replaceDefs = R.compose(detachDefs, parseDefs);
var _default = replaceDefs;
exports.default = _default;
export default exports;