import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _matchPercent2 from "../utils/matchPercent";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _matchPercent = _interopRequireDefault(_matchPercent2);
/*
 * Translates page percentage horizontal paddings in fixed ones
 *
 * @param {Object} page container
 * @param {String} padding value
 * @returns {Object} translated padding value
 */


var resolvePageHorizontalPadding = function resolvePageHorizontalPadding(container) {
  return function (value) {
    var match = (0, _matchPercent.default)(value);
    return match ? match.percent * container.width : value;
  };
};
/**
 * Translates page percentage vertical paddings in fixed ones
 *
 * @param {Object} page container
 * @param {String} padding value
 * @returns {Object} translated padding value
 */


var resolvePageVerticalPadding = function resolvePageVerticalPadding(container) {
  return function (value) {
    var match = (0, _matchPercent.default)(value);
    return match ? match.percent * container.height : value;
  };
};
/**
 * Translates page percentage paddings in fixed ones
 *
 * @param {Object} page
 * @returns {Object} page with fixed paddings
 */


var resolvePagePaddings = function resolvePagePaddings(page) {
  var container = page.style;
  return R.evolve({
    style: R.evolve({
      paddingTop: resolvePageVerticalPadding(container),
      paddingLeft: resolvePageHorizontalPadding(container),
      paddingRight: resolvePageHorizontalPadding(container),
      paddingBottom: resolvePageVerticalPadding(container)
    })
  })(page);
};
/**
 * Translates all pages percentage paddings in fixed ones
 * This has to be computed from pages calculated size and not by Yoga
 * because at this point we didn't performed pagination yet.
 *
 * @param {Object} document root
 * @returns {Object} document root with translated page paddings
 */


var _default = R.evolve({
  children: R.map(resolvePagePaddings)
});

exports.default = _default;
export default exports;