import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _matchPercent2 from "../utils/matchPercent";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _matchPercent = _interopRequireDefault(_matchPercent2);

var getTransformStyle = function getTransformStyle(s) {
  return R.pathOr("50%", ["style", s]);
};
/**
 * Get node origin
 *
 * @param {Object} node
 * @returns {Object} node origin
 */


var getOrigin = function getOrigin(node) {
  if (!node.box) return {};
  var _node$box = node.box,
      left = _node$box.left,
      top = _node$box.top,
      width = _node$box.width,
      height = _node$box.height;
  var transformOriginX = getTransformStyle("transformOriginX")(node);
  var transformOriginY = getTransformStyle("transformOriginY")(node);
  var percentX = (0, _matchPercent.default)(transformOriginX);
  var percentY = (0, _matchPercent.default)(transformOriginY);
  var offsetX = percentX ? width * percentX.percent : transformOriginX;
  var offsetY = percentY ? height * percentY.percent : transformOriginY;
  return {
    left: left + offsetX,
    top: top + offsetY
  };
};

var _default = getOrigin;
exports.default = _default;
export default exports;