import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _primitives2 from "@react-pdf/primitives";

var _primitives = "default" in _primitives2 ? _primitives2.default : _primitives2;

import * as _layout3 from "@react-pdf/textkit/lib/layout";

var _layout2 = "default" in _layout3 ? _layout3.default : _layout3;

import * as _linebreaker3 from "@react-pdf/textkit/lib/engines/linebreaker";

var _linebreaker2 = "default" in _linebreaker3 ? _linebreaker3.default : _linebreaker3;

import * as _attributedString3 from "@react-pdf/textkit/lib/attributedString";

var _attributedString2 = "default" in _attributedString3 ? _attributedString3.default : _attributedString3;

import * as _justification3 from "@react-pdf/textkit/lib/engines/justification";

var _justification2 = "default" in _justification3 ? _justification3.default : _justification3;

import * as _scriptItemizer3 from "@react-pdf/textkit/lib/engines/scriptItemizer";

var _scriptItemizer2 = "default" in _scriptItemizer3 ? _scriptItemizer3.default : _scriptItemizer3;

import * as _wordHyphenation3 from "@react-pdf/textkit/lib/engines/wordHyphenation";

var _wordHyphenation2 = "default" in _wordHyphenation3 ? _wordHyphenation3.default : _wordHyphenation3;

import * as _textDecoration3 from "@react-pdf/textkit/lib/engines/textDecoration";

var _textDecoration2 = "default" in _textDecoration3 ? _textDecoration3.default : _textDecoration3;

import _transformText2 from "../text/transformText";
import _fontSubstitution2 from "../text/fontSubstitution";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var P = _interopRequireWildcard(_primitives);

var _layout = _interopRequireDefault(_layout2);

var _linebreaker = _interopRequireDefault(_linebreaker2);

var _attributedString = _interopRequireDefault(_attributedString2);

var _justification = _interopRequireDefault(_justification2);

var _scriptItemizer = _interopRequireDefault(_scriptItemizer2);

var _wordHyphenation = _interopRequireDefault(_wordHyphenation2);

var _textDecoration = _interopRequireDefault(_textDecoration2);

var _transformText = _interopRequireDefault(_transformText2);

var _fontSubstitution = _interopRequireDefault(_fontSubstitution2);

var isTextInstance = R.propEq("type", P.TextInstance);
var engines = {
  linebreaker: _linebreaker.default,
  justification: _justification.default,
  scriptItemizer: _scriptItemizer.default,
  wordHyphenation: _wordHyphenation.default,
  fontSubstitution: _fontSubstitution.default,
  textDecoration: _textDecoration.default
};
var engine = (0, _layout.default)(engines);

var getFragments = function getFragments(fontStore, instance) {
  if (!instance) return [{
    string: ""
  }];
  var fragments = [];
  var _instance$props = instance.props,
      _instance$props$fill = _instance$props.fill,
      fill = _instance$props$fill === void 0 ? "black" : _instance$props$fill,
      _instance$props$fontF = _instance$props.fontFamily,
      fontFamily = _instance$props$fontF === void 0 ? "Helvetica" : _instance$props$fontF,
      fontWeight = _instance$props.fontWeight,
      fontStyle = _instance$props.fontStyle,
      _instance$props$fontS = _instance$props.fontSize,
      fontSize = _instance$props$fontS === void 0 ? 18 : _instance$props$fontS,
      textDecoration = _instance$props.textDecoration,
      textDecorationColor = _instance$props.textDecorationColor,
      textDecorationStyle = _instance$props.textDecorationStyle,
      textTransform = _instance$props.textTransform,
      opacity = _instance$props.opacity;
  var obj = fontStore ? fontStore.getFont({
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontStyle: fontStyle
  }) : null;
  var font = obj ? obj.data : fontFamily;
  var attributes = {
    font: font,
    opacity: opacity,
    fontSize: fontSize,
    color: fill,
    underlineStyle: textDecorationStyle,
    underline: textDecoration === "underline" || textDecoration === "underline line-through" || textDecoration === "line-through underline",
    underlineColor: textDecorationColor || fill,
    strike: textDecoration === "line-through" || textDecoration === "underline line-through" || textDecoration === "line-through underline",
    strikeStyle: textDecorationStyle,
    strikeColor: textDecorationColor || fill
  };

  for (var i = 0; i < instance.children.length; i += 1) {
    var child = instance.children[i];

    if (isTextInstance(child)) {
      fragments.push({
        string: (0, _transformText.default)(child.value, textTransform),
        attributes: attributes
      });
    } else if (child) {
      fragments.push.apply(fragments, getFragments(child));
    }
  }

  return fragments;
};

var getAttributedString = function getAttributedString(fontStore, instance) {
  return _attributedString.default.fromFragments(getFragments(fontStore, instance));
};

var AlmostInfinity = 999999999999;
var shrinkWhitespaceFactor = {
  before: -0.5,
  after: -0.5
};

var layoutTspan = function layoutTspan(fontStore) {
  return function (node) {
    var attributedString = getAttributedString(fontStore, node);
    var x = R.pathOr(0, ["props", "x"], node);
    var y = R.pathOr(0, ["props", "y"], node);
    var container = {
      x: x,
      y: y,
      width: AlmostInfinity,
      height: AlmostInfinity
    };
    var hyphenationCallback = node.props.hyphenationCallback || (fontStore === null || fontStore === void 0 ? void 0 : fontStore.getHyphenationCallback()) || null;
    var layoutOptions = {
      hyphenationCallback: hyphenationCallback,
      shrinkWhitespaceFactor: shrinkWhitespaceFactor
    };
    var lines = R.compose(R.reduce(R.concat, []), engine)(attributedString, container, layoutOptions);
    return R.assoc("lines", lines, node);
  };
};

var layoutText = function layoutText(fontStore, node) {
  return R.evolve({
    children: R.map(layoutTspan(fontStore))
  })(node);
};

var _default = R.curryN(2, layoutText);

exports.default = _default;
export default exports;