import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var SVG_INHERITED_PROPS = ["x", "y", "clipPath", "clipRule", "opacity", "fill", "fillOpacity", "fillRule", "stroke", "strokeLinecap", "strokeLinejoin", "strokeOpacity", "strokeWidth", "textAnchor", "dominantBaseline", "color", "fontFamily", "fontSize", "fontStyle", "fontWeight", "letterSpacing", "opacity", "textDecoration", "lineHeight", "textAlign", "visibility", "wordSpacing"];
var getInheritProps = R.compose(R.pick(SVG_INHERITED_PROPS), R.propOr({}, "props"));

var inheritProps = function inheritProps(node) {
  var props = getInheritProps(node);
  return R.evolve({
    children: R.map(R.compose(inheritProps, R.evolve({
      props: R.merge(props)
    })))
  })(node);
};

var _default = inheritProps;
exports.default = _default;
export default exports;